import React, {useEffect, useState} from 'react';
import { Box, Flex, Heading, Text, Image, IconButton } from "@chakra-ui/react";
import { FaShare } from "react-icons/fa";
import Imagetravis from './../Rectangle 1.png';
import { useLocation, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from "react-markdown";
import Markdown from 'markdown-to-jsx';



const News = () => {

  const {lang} = useParams();
  const languages = ['ru','en'];
    const defaultLanguage = languages[0];
    const selectedLanguage = languages.find(elem => elem === lang);
      const lgng = (selectedLanguage ? selectedLanguage : defaultLanguage);

    const locate = useLocation();

 const apiURL = `https://admin.sati.show/api/news?populate=*&sort=createdAt:desc`;
   const [news, setNews] = useState([]); 

  useEffect(() => {
        
      getNews();
      const onPageLoad = () => {
       // setPreload(false);
        // do something else
      };
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getNews();
  }, [locate.pathname]);
 
  const getNews = () => {
    axios.get(apiURL)
    .then(function (response) {
      setNews(response.data.data);
        console.log(response.data.data)
      //setPreload(false);

    })
    .catch(function (error) {
      console.log('error');
    })
  }

  return (
    <>
    <Heading
      fontSize="36px"
      textAlign="center"
      display={{ base: "block", xl: "none" }}
      p="0 15px"
      mb="32px"
      mt={{base: "81px", md: "152px"}}
    >
            {(!selectedLanguage || selectedLanguage == 'ru' ? 'Новости' : 'News')}

    </Heading>
    <Flex maxW="719px" mx="auto" flexDirection="column" mt="20px"
     mt={{base: "20px", md: "152px"}}>
      {news.map((newsItem,index) => (

        <Flex key={newsItem.index} pb={index !== news.length - 1 ? "24px" : 0} mb={index !== news.length - 1 ? "24px" : 0} borderBottom={index !== news.length - 1 ? "1px solid" : 0} borderColor={index !== news.length - 1 ? "black" : 0} pl={{base: "15px", md: "0px"}} pr={{base: "15px", md: "0px"}} flexDirection={{base: "column", md: "row"}}>
          <Image
            src={'https://admin.sati.show'+newsItem.attributes.img.data.attributes.formats.small.url}
            alt={newsItem.title}
            boxSize={{base: "100%", md: "240px"}}
            objectFit="cover"
            flex={{base: "0 0 100%", md: "0 0 240px"}}
            mr={{base: "0px", md: "34px"}}
            display={{base: "block", md: "block"}}
 aspectRatio={1}

          />
          <Flex flexDirection="column" justifyContent="center">
            <Heading fontSize={{base: "18px", sm: "24px"}} mb="12px" mt={{base: "12px", md: "0px"}}>
              {newsItem.attributes.title}
            </Heading>
            <Text mb="20px" fontSize={{base: "14px", sm: "16px"}}>
            
            <Markdown className="wrr">{newsItem.attributes.description.replace(/\n/gi, "&nbsp; \n")}</Markdown>


            </Text>

              <Box>
                <Link to="#" fontWeight="700">Поделиться</Link>
              </Box>

          </Flex>
        </Flex>
      ))}
    </Flex>
    </>
  );
};

export default News;
